<template>
  <div>
    <Error404 v-if="error404occured" />
    <main v-else id="content" role="main" class="overflow-hidden">
      <!-- ========== Job Description ========== -->
      <div class="bg-light">
        <div class="container space-top-3 space-bottom-2 space-bottom-lg-3">
          <div class="w-lg-90 mx-auto">
            <!-- Breadcrumb -->
            <div class="mb-3">
              <!-- <a class="font-weight-bold" href="/careers#openroles" @click="scrollToList()"> -->
              <router-link class="font-weight-bold" :to="{ path: '/careers', hash: '#openroles' }" @click="scrollToList()">
                <i class="fas fa-angle-left mr-1" /> All beNovelty Open Positions
              </router-link>
              <!-- </a> -->
            </div>

            <!-- Top Banner -->
            <div class="position-relative bg-img-hero gradient-x-overlay-lg-navy-light" :style="{'background-image': 'url(' + require('@/assets/svg/components/'+image_url) + ')'}">
              <div class="container text-center position-relative z-index-2 space-md-3 space-2">
                <h5 class="text-white">{{ category }}</h5>
                <h1 class="text-white">{{ position_datail.title }}</h1>
              </div>

              <!-- SVG Shapes -->
              <figure class="position-absolute right-0 bottom-0 left-0 mb-n1">
                <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                  <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                </svg>
              </figure>
            </div>

            <!--Content-->
            <div class="card">
              <div class="card-body px-5 py-8 p-md-8 px-lg-11 py-lg-8">
                <p v-if="position_datail.description" class="mb-8">{{ position_datail.description }}</p>

                <div class="mb-8">
                  <div class="media align-items-center mb-3">
                    <span class="icon icon-sm icon-soft-primary icon-circle mr-3">
                      <i class="fas fa-briefcase" />
                    </span>
                    <div class="media-body">
                      <h3 class="mb-0">About the Role</h3>
                    </div>
                  </div>
                  <div id="about" />
                </div>

                <div class="mb-8">
                  <div class="media align-items-center mb-3">
                    <span class="icon icon-sm icon-soft-primary icon-circle mr-3">
                      <i class="fas fa-tools" />
                    </span>
                    <div class="media-body">
                      <h3 class="mb-0">Skills &amp; Requirements</h3>
                    </div>
                  </div>
                  <div id="requirement" />
                </div>

                <div class="mb-8">
                  <div class="media align-items-center mb-3">
                    <span class="icon icon-sm icon-soft-primary icon-circle mr-3">
                      <i class="fas fa-hand-holding-heart" />
                    </span>
                    <div class="media-body">
                      <h3 class="mb-0">Team Benefits</h3>
                    </div>
                  </div>
                  <ul><li>5-day work week with flexible working hours</li><li>Hybrid working model allowing staff working remotely 40-60% under the new business normal</li><li>Flat company structure and working directly with founder and core members</li><li>On the job training and coaching by certified leaders in Technology Consulting</li><li>Fun and great team! We host monthly event to celebrate the hard work of the team! We got 30+ board games, gaming consoles, guitars, piano, ukuleles in office for fun and enjoyment</li></ul>
                </div>

                <div class="mb-8">
                  <h3>About beNovelty</h3>
                  <p>beNovelty is an award-winning API technology company with a focus on emerging API technology and we are actively driving the development of a multi-disciplined community of API developers from different industries. Our cloud-based API platform – OpenAPIHub – helps companies to implement brand-new and scalable API business models and assists to design, manage, secure and commercialize APIs for applications such as banking (open banking), insurance, hospitality and tourism, education, healthcare, catering and more. beNovelty is a recent featured company in the campaign “Local Business Stories” launched by HSBC, and continuously work closely with partners in various industries to meet the business needs of digital transformation through API technology.</p>
                  <p>Awards and Recognitions:</p>
                  <ul>
                    <li>ERB Manpower Developer Award - Manpower Developer (2020-2025)</li>
                    <li>Corporate Innovation Index (CII) Awards (2022)</li>
                    <li>Hong Kong ICT Start-up Awards (Silver Award - Software &amp; App) (2020)</li>
                    <li>HKET Fintech Awards 2020 - Outstanding Cloud-based API Platform (2020)</li>
                    <li>IFTA Fintech Achievement Awards (Platinum Awards - Cloud Computing) (2019)</li>
                  </ul>
                </div>

                <div class="mb-8">
                  <p>Interested parties please send your <span class="font-weight-bold">full resume</span> with <span class="font-weight-bold">expected salary</span> in your application.</p>
                </div>

                <div class="text-center">
                  <a class="position-static btn btn-primary btn-wide transition-3d-hover" href="mailto: talents@beNovelty.com">
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ========== End Job Description ========== -->

      <!-- ========== beNovelty at a Glance ========== -->
      <div class="container space-2 space-lg-3">
        <div class="w-90 mx-auto">
          <h2 class="mb-3">beNovelty at a Glance</h2>
          <div class="row">
            <div class="col-12 col-md-6 mb-3 mb-md-0">
              <div class="card h-100">
                <div class="card-body">
                  <div id="youTubeVideoPlayer1" class="video-player bg-dark mb-4">

                    <img class="img-fluid video-player-preview" src="@/assets/img/images/hk10x-cover2.jpg">
                    <a
                      class="js-fancybox video-player-btn video-player-centered"
                      href="javascript:;"
                      data-hs-fancybox-options="{
                        &quot;src&quot;: &quot;//www.youtube.com/watch?v=uL_bzOBTTuo&quot;,
                        &quot;caption&quot;: &quot;HK10X： 創科，是一種傳承&quot;,
                        &quot;speed&quot;: 700,
                        &quot;buttons&quot;: [&quot;fullScreen&quot;, &quot;close&quot;],
                        &quot;vimeo&quot;: {
                          &quot;autoplay&quot;: 1
                        }
                      }"
                    >
                      <span class="video-player-icon">
                        <i class="fas fa-play" />
                      </span>
                    </a>
                    <div class="embed-responsive embed-responsive-16by9">
                      <div id="youTubeVideoIframe1" />
                    </div>
                  </div>
                  <div class="mb-3">
                    <h3>HK10X—The Guiding Pioneering Spirit</h3>
                  </div>
                  <p>Human Touch is one of the most important corporate culture of beNovelty. With various mentoring relationships in the team, technology know-how and experience are being transferred more efficiently, and at the same time everyone learns from each other and grow together.</p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="card h-100">
                <div class="card-body">
                  <div id="" class="video-player bg-white mb-4">
                    <img class="img-fluid video-player-preview" src="@/assets/svg/illustrations/careers-work-happily.svg">
                    <div class="embed-responsive embed-responsive-16by9" />
                  </div>
                  <div class="mb-3">
                    <h3>Work Happily to Innovate and Create</h3>
                  </div>
                  <p>Running a company is like playing in a band. In order to build a synergistic team, beNovelty encourages everyone in the company to bring new ideas and innovations, regardless of level and function.</p>
                  <div class="mt-3">
                    <a class="font-weight-bold" href="javascript:;" data-toggle="modal" data-target="#wantedlyPress">Learn More<i class="fas fa-angle-right fa-sm ml-2" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ========== End beNovelty at a Glance ========== -->

      <!-- ========== Popup Modals ========== -->
      <!-- Wantedly -->
      <div id="wantedlyPress" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="wantedlyPressTitle" aria-hidden="true">
        <div class="modal-xl modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="wantedlyPressTitle" class="modal-title">Wantedly—20200103</h5>
              <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                  <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                </svg>
              </button>
            </div>
            <div class="modal-body">
              <div class="mb-4">
                <span>Original Source From <a href="https://hk.wantedly.com/companies/benovelty/post_articles/202030" target="_blank">Wantedly</a></span>
              </div>
              <img class="w-100 img-fluid" src="@/assets/img/images/wantedly-20200103.jpg">
            </div>
          </div>
        </div>
      </div>
      <!-- Wantedly -->
      <!-- ========== End Popup Modals ========== -->

      <!-- ========== CTA ========== -->
      <div class="bg-light">
        <div class="container text-center cta-bg-rocket space-2 space-lg-3" data-aos="fade-up">
          <div class="w-md-80 w-lg-60 mx-auto">
            <h2>{{ cta_title }}</h2>
          </div>
          <p class="lead">{{ cta_description }}</p>
          <span class="d-block mt-5">
            <!-- <a class="btn btn-primary transition-3d-hover" href="/careers"> -->
            <router-link class="btn btn-primary transition-3d-hover" :to="{ path: '/careers' }">
              Explore Life at beNovelty
            </router-link>
            <!-- </a> -->
          </span>
        </div>
      </div>
      <!-- ========== End CTA ========== -->

    </main>
  </div>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')
import HSGoTo from '../../../assets/vendor/hs-go-to/src/js/hs-go-to'
import Error404 from '@/views/error404/index'
import '../../../assets/js/hs.core.js'
import '../../../assets/js/hs.fancybox.js'
import '../../../assets/vendor/fancybox/dist/jquery.fancybox.js'
import { mapGetters } from 'vuex'
export default {
  name: 'Position',
  components: {
    Error404
  },
  data() {
    return {
      category: '',
      image_url: '',
      position_datail: {},
      url: '',
      error404occured: false,
      career_press_list: [
        {
          url: 'https://www.youtube.com/watch?t=143&v=zOdVbmbzrc8&feature=youtu.be',
          image_url: 'tvb-pearl-magazine-2020.jpg',
          title: 'TVB Pearl Magazine - Class of Covid',
          organization: 'TVB Pearl',
          date: 'Auguest 26, 2020'
        },
        {
          url: 'https://www.youtube.com/watch?t=143&v=zOdVbmbzrc8&feature=youtu.be',
          image_url: 'tvb-pearl-magazine-2020.jpg',
          title: 'TVB Pearl Magazine - Class of Covid',
          organization: 'TVB Pearl',
          date: 'Auguest 26, 2020'
        },
        {
          url: 'https://www.youtube.com/watch?t=143&v=zOdVbmbzrc8&feature=youtu.be',
          image_url: 'tvb-pearl-magazine-2020.jpg',
          title: 'TVB Pearl Magazine - Class of Covid',
          organization: 'TVB Pearl',
          date: 'Auguest 26, 2020'
        },
        {
          url: 'https://www.hk01.com/%E9%96%8B%E7%BD%90/461059/hello-%E6%9C%AA%E4%BE%86%E4%BD%A0%E5%A5%BD-%E7%A7%91%E5%AD%B8%E5%9C%92%E5%90%B8%E6%89%8D%E5%8A%A9%E5%89%B5%E7%A7%91-%E7%B6%B2%E4%B8%8A%E6%8B%9B%E8%81%98%E7%8D%B2%E4%BC%81%E6%A5%AD%E7%9B%9B%E8%AE%9A',
          image_url: 'hk01-career-expo-2020.jpg',
          title: '【Hello，未來你好！】科學園吸才助創科　網上招聘獲企業盛讚',
          organization: 'HK01',
          date: 'April 17, 2020'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'careers'
    ])
  },
  async mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-fancybox').each(function() {
      $.HSCore.components.HSFancyBox.init($(this))
    })
    document.getElementById('about').innerHTML = this.position_datail.about
    document.getElementById('requirement').innerHTML = this.position_datail.requirement
    // document.getElementById('obtainment').innerHTML = this.position_datail.obtainment
  },
  created() {
    this.url = this.$route.params && this.$route.params.url
    const position_list = this.$store.getters.careers.position_list
    // for (let i = 0; i < position_list.length; i++) {
    //   for (let j = 0; j < position_list[i].position.length; j++) {
    //     if (position_list[i].position[j].url === this.url) {
    //       this.position_datail = position_list[i].position[j]
    //       this.image_url = position_list[i].image_url
    //       this.category = position_list[i].category
    //     }
    //   }
    // }

    // Check whether have the info of the parms
    let i = 0
    let found = false
    while ((i < position_list.length) && (found === false)) {
      let j = 0
      while ((j < position_list[i].position.length) && (found === false)) {
        if (position_list[i].position[j].url === this.url) {
          this.position_datail = position_list[i].position[j]
          this.image_url = position_list[i].image_url
          this.category = position_list[i].category
          this.cta_title = position_list[i].cta_title
          this.cta_description = position_list[i].cta_description
          found = true
        } else {
          j = j + 1
        }
      }
      i = i + 1
    }
    // If not, redirect to error 404 page
    if (found === false) {
      this.error404occured = true
    }
  },
  methods: {
    scrollToList() {
      this.$store.state.careers.scrollToList = true
    }
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: this.position_datail.title + ' | Careers | %s',
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: this.position_datail.title + ' | Careers | beNovelty' },
        { property: 'og:description', content: this.position_datail.about.substring(3, this.position_datail.about.indexOf('</p>')) + '.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/careers.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/careers/' + this.url },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../assets/vendor/fancybox/dist/jquery.fancybox.min.css");
</style>
